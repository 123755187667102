'use client'

import { Container, FlexContainer, LineSeparator } from '@news12/storybook-news'

import React, { Fragment } from 'react'

import { StoryListByCategory } from '../../data/query/homepage/fetchHomepageData'
import useWindowDimension from '../../hooks/useWindowDimension'
import AdUnit from '../Ads/AdUnit'
import StoryCollectionCarousel from './StoryCollectionCarousel'

import { useTheme } from 'styled-components'

type CollectionsProps = {
  lists: StoryListByCategory[]
}

const StoryCollections = ({ lists }: CollectionsProps) => {
  const theme = useTheme()
  const { width = 0 } = useWindowDimension()

  const smallBreakpoint = parseInt(theme.breakpoints.sm)
  const isMobile = width <= smallBreakpoint

  return lists.map((collection, listsIndex) => (
    <Fragment key={collection.category}>
      {listsIndex === 3 && !isMobile && (
        <Container>
          <LineSeparator margin={['1.5rem auto', '2.5rem auto']} />
          <FlexContainer justifyContent='center'>
            <AdUnit adUnitTemplate='leaderboard' id='ad-banner-middle-2' type='leaderboardDynamic' />
          </FlexContainer>
        </Container>
      )}
      {Boolean(listsIndex) && (
        <Container>
          <LineSeparator margin={['1.5rem auto', '2.5rem auto']} />
        </Container>
      )}
      <StoryCollectionCarousel collection={collection} withAds={listsIndex === 1 || listsIndex === 4} />
      {isMobile && (
        <Container margin={listsIndex === lists.length - 1 ? '0 0 1.5rem' : ''}>
          <LineSeparator margin={['1.5rem auto', '2.5rem auto']} />
          <FlexContainer justifyContent='center'>
            <AdUnit
              adUnitTemplate='leaderboard'
              id={`ad-banner-middle-mobile-${listsIndex}`}
              type='leaderboardDynamic'
            />
          </FlexContainer>
        </Container>
      )}
    </Fragment>
  ))
}

export default StoryCollections
