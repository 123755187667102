import { HorizontalCarousel, HorizontalCarouselAPI, HorizontalCarouselProps } from '@news12/storybook-news'

import { useCallback } from 'react'

type InfiniteHorizontalCarouselProps = HorizontalCarouselProps & {
  onLoadMore: () => void
}

const InfiniteHorizontalCarousel = ({ children, onInit, onLoadMore, ...rest }: InfiniteHorizontalCarouselProps) => {
  const handleCarouseInit = useCallback(
    (carouselApi: HorizontalCarouselAPI) => {
      carouselApi.on('select', event => {
        if (!event.canScrollNext()) {
          onLoadMore()
        }
      })

      onInit && onInit(carouselApi)
    },
    [onLoadMore, onInit]
  )

  return (
    <HorizontalCarousel options={{ slidesToScroll: 1 }} onInit={handleCarouseInit} {...rest}>
      {children}
    </HorizontalCarousel>
  )
}

export default InfiniteHorizontalCarousel
